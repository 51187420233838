import Color from "color";
import { Model, ModelAttributes, collection, model, modelConfig } from "@mixitone/oom";
import { isNil, isPresent } from "@mixitone/util";
import ClubPlayer from "./ClubPlayer";
import { setAccountId } from "./concerns/SetAccountId";

export const defaultColors = [
  "#F44336",
  "#9C27B0",
  "#2196F3",
  "#4CAF50",
  "#FFEB3B",
  "#FF5722",
  "#9E9E9E",
  "#3F51B5",
  "#E91E63",
  "#673AB7",
  "#CDDC39",
  "#795548",
  "#607D8B",
  "#FFC107",
  "#009688",
];

export const bgToTextColor: Record<string, string> = {
  "#F44336": "#fff",
  "#9C27B0": "#fff",
  "#2196F3": "#fff",
  "#4CAF50": "#fff",
  "#FFEB3B": "#000",
  "#FF5722": "#fff",
  "#9E9E9E": "#fff",
  "#3F51B5": "#fff",
  "#E91E63": "#fff",
  "#673AB7": "#fff",
  "#CDDC39": "#000",
  "#795548": "#fff",
  "#607D8B": "#fff",
  "#FFC107": "#000",
  "#009688": "#fff",
};

function generateColor(index: number) {
  const hash = hashIndex(index);
  const r = (hash & 0xff0000) >> 16;
  const g = (hash & 0x00ff00) >> 8;
  const b = hash & 0x0000ff;
  return (r << 16) + (g << 8) + b;
}

function hashIndex(index: number) {
  let hash = 0;
  if (index === 0) return hash;
  for (let i = 0; i < index.toString().length; i++) {
    const char = index.toString().charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    club_id: {
      type: "string",
    },
    color: {
      type: "number",
    },
    configuration: {
      type: "json",
      jsonType: {
        excludedGroups: [],
      } as {
        excludedGroups: string[];
      },
    },
    index: {
      type: "number",
    },
    initial: {
      type: "string",
    },
    is_deleted: {
      type: "boolean",
    },
    name: {
      type: "string",
    },
    updated_at: {
      type: "datetime",
    },
  },
  {
    players: collection(() => ClubPlayer, "group_id", {
      order: "name",
    }),
  },
  {},
);

interface Group extends ModelAttributes<typeof config> {}

@setAccountId
@model("groups", config)
class Group extends Model<typeof config> {
  override save() {
    if (isNil(this.color) && !isNil(this.index)) {
      const color = defaultColors[this.index] || generateColor(this.index);
      this.color = Color(color).rgbNumber();
    }

    if (isNil(this.initial && isPresent(this.name))) {
      this.initial = this.name![0];
    }

    if (isNil(this.configuration)) {
      this.configuration = { excludedGroups: [] };
    }

    return super.save();
  }

  get asColor(): Color<any> {
    const color = Color(this.color || defaultColors[this.index || 0] || generateColor(this.index || 0));
    if (isNil(this.color)) {
      this.color = color.rgbNumber();
    }
    return color;
  }

  get excludesGroups() {
    return this.configuration?.excludedGroups || [];
  }

  excludesGroup(id: string) {
    this.excludesGroups.includes(id);
  }

  addExcludeGroup(id: string) {
    this.configuration = {
      ...this.configuration,
      excludedGroups: [...this.excludesGroups, id],
    };
  }

  removeExcludeGroup(id: string) {
    this.configuration = {
      ...this.configuration,
      excludedGroups: this.excludesGroups.filter((gid) => gid !== id),
    };
  }
}

export default Group;
