import { bindControllerState, Button, Input, LabelledField, TextArea } from "@mixitone/components";
import { ApplicationView } from "@mixitone/mvc";
import { isNil } from "@mixitone/util/util";
import clsx from "clsx";
import { AppController } from "controllers/AppController";
import { useCallback } from "react";
import { ContactFormController } from "./ContactFormController";

interface Props {
  onClose: () => void;
  className?: string;
}

const ContactForm: React.FC<Props> = ({ onClose, className }) => {
  const controller = ContactFormController.use();
  const { formData, sending, sent, error } = controller.state;

  const appController = AppController.use();
  const { online } = appController.state;

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      onClose();
      controller.actionReset();
    },
    [onClose],
  );

  const handleSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault();
    controller.actionSubmit();
  }, []);

  const { bind } = bindControllerState(controller, "formData");

  return (
    <div className={className}>
      {error && (
        <div className="p-6">
          <div>There was an error sending your message. Please try again later.</div>
          <Button className="mt-4 w-full" onClick={handleClose}>
            Close
          </Button>
        </div>
      )}
      {isNil(error) && (
        <div className="relative">
          <div
            className={clsx("p-6 transition-opacity duration-100", {
              "opacity-100": !sent,
              "opacity-0": sent,
            })}
          >
            {!online && (
              <>
                <div>You are offline. Connect to the internet to send us a message.</div>
                <Button className="mt-4 w-full" onClick={handleClose}>
                  Close
                </Button>
              </>
            )}
            {online && (
              <form onSubmit={handleSubmit}>
                <LabelledField label="Name" htmlFor="name">
                  <Input
                    type="text"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    data-1p-ignore
                    required
                    autoFocus={formData.name === ""}
                    {...bind("name")}
                  />
                </LabelledField>
                <LabelledField label="Email" htmlFor="email">
                  <Input
                    type="email"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    required
                    data-1p-ignore
                    {...bind("email")}
                  />
                </LabelledField>
                <LabelledField label="Message" htmlFor="message">
                  <TextArea
                    rows={5}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    required
                    autoFocus={formData.email !== ""}
                    {...bind("message")}
                  />
                </LabelledField>
                <Button type="submit" className="w-full" spinner={sending} disabled={sending}>
                  Send message
                </Button>
                <p className="mt-2 text-xs">
                  Messages sent to us are private and will not be shared with any third parties.
                </p>
              </form>
            )}
          </div>
          <div
            className={clsx("absolute top-0 p-6 transition-opacity duration-100", {
              "pointer-events-none opacity-0": !sent,
              "opacity-100": sent,
            })}
          >
            <div>Thank you for your message. We will get back to you as soon as possible.</div>
            <Button className="mt-4 w-full" onClick={handleClose}>
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactFormController.scope(ApplicationView(ContactForm));
