import { bindController, Button, TextArea } from "@mixitone/components";
import SendIcon from "@mixitone/components/icons/send.svg";
import { ApplicationView } from "@mixitone/mvc";
import clsx from "clsx";
import { useCallback, useEffect, useRef } from "react";
import ContactForm from "./ContactForm";
import { FeedbackMessagesController } from "./FeedbackMessagesController";
import MessageRow from "./MessageRow";
import Popup from "./Popup";
import { AppController } from "controllers/AppController";

const FeedbackMessages = () => {
  const appController = AppController.use();
  const { online } = appController.state;

  const controller = FeedbackMessagesController.use();
  const { messages, showForm, sending, messageBeingSent } = controller.state;

  const bind = bindController(controller);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const scrolled = useRef(false);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: scrolled.current ? "smooth" : "instant" });
    scrolled.current = true;
  }, []);

  const handleClose = useCallback(() => {
    controller.actionShowMessages(false);
  }, []);

  const handleReply = useCallback((e: React.MouseEvent) => {
    controller.actionSendMessage();
  }, []);

  // Scroll when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages.length, sending, scrollToBottom]);

  useEffect(() => {
    if (!sending && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [sending]);

  return (
    <Popup
      title="Feedback"
      testId="feedback"
      onClose={handleClose}
      className="min-h-[512px] min-w-[375px] shadow-black/30 flex flex-col"
    >
      {showForm && <ContactForm onClose={handleClose} />}

      {!showForm && (
        <div
          className={clsx("flex flex-grow min-h-full flex-col overflow-y-auto rounded-b-lg bg-white", {
            "pointer-events-none": showForm,
            "opacity-0": showForm,
          })}
        >
          <div className="flex flex-grow flex-col gap-4 p-4">
            {messages.map((message) => (
              <MessageRow key={message.domKey()} message={message} />
            ))}
            {messageBeingSent && <MessageRow message={messageBeingSent} />}
            <div ref={messagesEndRef} /> {/* Anchor element for scrolling */}
          </div>
          <div className="p-2">
            <div className="flex w-full items-center gap-2 rounded-lg bg-gray-100 pr-2 shadow">
              <TextArea
                ref={textAreaRef}
                disabled={!online || sending}
                autoFocus={!showForm}
                rows={2}
                className={clsx("m-0 resize-none rounded-bl-lg border-0 bg-gray-100 p-0 outline-none", {
                  "cursor-not-allowed": !online || sending,
                  "text-sm placeholder-gray-500": !online,
                })}
                placeholder={
                  online
                    ? "Enter your message here"
                    : "You are offline. Connect to the internet to send us a message."
                }
                {...bind("messageText")}
              ></TextArea>
              <Button
                round
                className="h-[45px] max-h-[45px] min-h-[45px] w-[45px] min-w-[45px] max-w-[45px] p-0"
                disabled={!online || sending}
                spinner={sending}
                onClick={handleReply}
              >
                <SendIcon className="w-[24px] -translate-x-[1px] translate-y-[1px] fill-white stroke-none" />
                <span className="sr-only">Send</span>
              </Button>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default ApplicationView(FeedbackMessages);
