import clsx from "clsx";
import React, { useEffect, useRef } from "react";
import { Button } from "./Button";

type ButtonProps = React.ComponentProps<typeof Button>;

interface ButtonGroupProps {
  children: React.ReactElement<ButtonProps> | Array<React.ReactElement<ButtonProps> | null | false>;
  className?: string;
  style?: React.CSSProperties;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({ children, className, style }) => {
  const childCount = React.Children.count(children);

  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!divRef.current) return;

    const children = divRef.current.children;
    if (!children.length) return;
    const removeBorders = !className?.includes("divide");

    for (let index = 0; index < children.length; index++) {
      const child = children[index] as HTMLElement;
      child.style.boxShadow = "none";

      if (index === 0) {
        if (removeBorders) child.style.borderRight = "none";
        child.style.borderTopRightRadius = "0";
        child.style.borderBottomRightRadius = "0";
      } else if (index === children.length - 1) {
        if (removeBorders) child.style.borderLeft = "none";
        child.style.borderTopLeftRadius = "0";
        child.style.borderBottomLeftRadius = "0";
      } else {
        if (removeBorders) {
          child.style.borderLeft = "none";
          child.style.borderRight = "none";
        }
        child.style.borderTopLeftRadius = "0";
        child.style.borderBottomLeftRadius = "0";
        child.style.borderTopRightRadius = "0";
        child.style.borderBottomRightRadius = "0";
      }
    }
  });

  return (
    <div ref={divRef} className={clsx(`grid grid-flow-col`, className, {})} style={style}>
      {React.Children.map(children, (child, index) => {
        if (!child) return null;

        if (childCount === 1) {
          // Do not modify the rounded if there is only one child
          return React.cloneElement(child as React.ReactElement);
        }

        if (index === 0) {
          // Round the left side of the first button
          return React.cloneElement(child, {
            groupPosition: "left",
          });
        } else if (index === childCount - 1) {
          // Round the right side of the last button
          return React.cloneElement(child, {
            groupPosition: "right",
          });
        } else {
          // Do not round the middle buttons
          return React.cloneElement(child, {
            groupPosition: "middle",
          });
        }
      })}
    </div>
  );
};

export { ButtonGroup };
