import Account from "@mixitone/models/Account";
import AccountUser from "@mixitone/models/AccountUser";
import { withStateToggle } from "@mixitone/util/concerns";
import { AppController } from "controllers/AppController";
import MixitoneController from "controllers/MixitoneController";
import SessionController from "controllers/SessionController";
import tauri from "lib/tauri";

interface State {
  formData: {
    name: string;
    email: string;
    message: string;
  };
  sending: boolean;
  sent: boolean;
  error?: string;
}

interface Props {}

export class ContactFormController extends MixitoneController<State, Props> {
  get initialState(): State {
    return {
      formData: {
        name: "",
        email: "",
        message: "",
      },
      sending: false,
      sent: false,
    };
  }

  async initialize(props: Props) {
    const session = this.get(SessionController).state.session;
    const user = session?.user;

    this.state.formData.name = AccountUser.current?.name || "";
    this.state.formData.email = user?.email || "";
  }

  @withStateToggle("sending")
  async actionSubmit() {
    const appController = this.get(AppController);

    try {
      const adapter = this.get(SessionController).adapter;
      await adapter.edgeFunction("feedback", {
        action: "sendFeedback",
        message: this.state.formData.message,
        email: this.state.formData.email,
        account_id: Account.current?.id,
        club_name: appController.state.currentClub?.name,
      });

      if (tauri.enabled) {
        await tauri.performSync();
      }
      this.state.sent = true;
    } catch (e) {
      console.log(e);
      if (e instanceof Error) this.state.error = e.message;
    }
  }

  actionReset() {
    this.resetState();
  }
}
