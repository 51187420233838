import clsx from "clsx";
import { ApplicationController, ControllerState } from "@mixitone/mvc";
import { isNil, omit } from "@mixitone/util";
import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, "prefix"> & {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  unstyled?: boolean;
  suffix?: string;
  prefix?: React.ReactNode;
  ref?: React.Ref<HTMLInputElement>;
};

export const Input: React.FC<Props> = ({ ref, ...props }) => {
  const [value, setValue] = useState(props.value);
  const rest = omit(props, ["value", "onChange", "suffix", "prefix"]);
  const { suffix, prefix, error } = props;

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
  }, [props.value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    props.onChange(e);
  };

  const className = props.unstyled
    ? ""
    : clsx("mt-1 block w-full rounded border border-gray-300 py-2", {
        "px-2": !prefix,
        "pl-5": prefix,
        "pr-2": prefix,
      });

  return (
    <>
      <div className="relative flex items-center">
        {prefix && <div className="absolute left-2 mt-1 text-sm text-gray-500">{prefix}</div>}
        <input
          value={value ?? ""}
          onChange={handleChange}
          {...omit(rest, ["unstyled"])}
          className={twMerge(className, rest.className)}
          ref={ref}
        />
        {suffix && <div className="absolute right-8 mt-1 text-sm text-gray-500">{suffix}</div>}
      </div>
      {error && <p className="mt-1 text-xs text-red-500">{error}</p>}
    </>
  );
};

/**
 * Create a bind function that binds controller state to input fields
 *
 * ```
 * const controller = SettingsModalController.use();
 * const bind = bindController(controller);
 *
 * return (
 *  <input {...bind("courts")} />
 * )
 */
export function bindController<C extends ApplicationController<any, any, any>>(controller: C) {
  return function <F extends keyof ControllerState<C>>(
    field: F,
    converter: (value: string) => ControllerState<C>[F] = (value: string) => value,
  ) {
    const value = controller.state[field];
    const error = controller.state.errors?.[field];

    return {
      name: field,
      id: field,
      value: String(isNil(value) ? "" : value) as string,
      onChange: (e: React.ChangeEvent<any>) => {
        if (typeof controller.state.errors === "object") {
          controller.setState({
            errors: {
              ...controller.state.errors,
              [field]: undefined,
            },
          });
        }
        controller.setState({
          [field]: converter(e.target.value),
        });
      },
      error,
    };
  };
}

// interface BindOptions<T> {
//   valueConverter?: (value: T) => string;
// }

export function bindControllerState<
  C extends ApplicationController<any, any, any>,
  K extends keyof ControllerState<C>,
>(controller: C, key: K) {
  const unsetErrors = function <F extends keyof ControllerState<C>[K]>(field: F) {
    if (typeof controller.state.errors === "object") {
      controller.setState({
        errors: {
          ...controller.state.errors,
          [field]: undefined,
        },
      });
    }
  };

  const bind = function <F extends keyof ControllerState<C>[K], T extends ControllerState<C>[K][F]>(
    field: F,
    converter: (value: string) => ControllerState<C>[K][F] = (value: string) => value,
    // options: BindOptions<T> = {},
  ) {
    // const valueConverter = options.valueConverter ?? ((value: T) => String(value));
    const value = controller.state[key][field] as T;
    const error = controller.state.errors?.[field];

    return {
      name: field,
      id: field,
      value,
      onChange: (e: React.ChangeEvent<any>) => {
        unsetErrors(field);
        controller.state[key][field] = converter(e.target.value);
      },
      error,
    };
  };

  const bindChecked = function <F extends keyof ControllerState<C>[K], T extends ControllerState<C>[K][F]>(
    field: F,
    converter: (value: boolean) => ControllerState<C>[K][F] = (value: boolean) => value,
  ) {
    const value = Boolean(controller.state[key][field] as T);
    const error = controller.state.errors?.[field];

    return {
      name: field,
      id: field,
      checked: value,
      onChange: (e: React.ChangeEvent<any>) => {
        unsetErrors(field);
        controller.state[key][field] = converter(e.target.checked);
      },
      error,
    };
  };

  return { bind, bindChecked };
}
