import { ApplicationView } from "@mixitone/mvc";
import { FeedbackMessagesController } from "./FeedbackMessagesController";
import { Pulse, SpeedDial, SpeedDialButton } from "@mixitone/components";
import ChatIcon from "@mixitone/components/icons/chat2.svg";
import FeedbackMessages from "./FeedbackMessages";

const FeedbackMessagesButton = () => {
  const controller = FeedbackMessagesController.use();
  const { showMessages, newMessages, loading } = controller.state;

  return (
    <>
      <SpeedDial>
        <SpeedDialButton
          data-testid="contact-button"
          onClick={() => {
            controller.actionShowMessages(true);
          }}
          disabled={loading}
        >
          <ChatIcon className="h-6 w-6" />
          {newMessages && <Pulse className="absolute right-1 top-1 z-10" size={4} />}
        </SpeedDialButton>
      </SpeedDial>
      {showMessages && <FeedbackMessages />}
    </>
  );
};

export default FeedbackMessagesController.scope(ApplicationView(FeedbackMessagesButton));
