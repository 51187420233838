import React from "react";
import { twMerge } from "tailwind-merge";

const Pulse: React.FC<{ className?: string; size?: number }> = ({ className, size }) => {
  const width = size || 3;
  const style = {
    width: `${width * 4}px`,
    height: `${width * 4}px`,
  };

  return (
    <span
      className={twMerge(
        "absolute right-0 top-0 z-10 -mr-1 -mt-1 flex transition-opacity duration-500",
        className,
      )}
      style={style}
    >
      <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-orange-600 opacity-75"></span>
      <span className="relative inline-flex rounded-full bg-orange-400" style={style}></span>
    </span>
  );
};

export { Pulse };
