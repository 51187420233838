import { Database } from "./database.types";
import { Model, ModelAttributes, model, modelConfig, reference } from "@mixitone/oom";
import AccountUser from "./AccountUser";

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    account_user_id: {
      type: "string",
    },
    amount_paid: {
      type: "number",
    },
    balance_after: {
      type: "number",
    },
    balance_before: {
      type: "number",
    },
    created_at: {
      type: "datetime",
    },
    credit_amount: {
      type: "number",
    },
    description: {
      type: "string",
    },
    is_deleted: {
      type: "boolean",
    },
    status: {
      type: "enum",
      enumType: "pending" as Database["public"]["Enums"]["credit_transaction_status"],
    },
    type: {
      type: "enum",
      enumType: "purchase" as Database["public"]["Enums"]["credit_transaction_type"],
    },
    updated_at: {
      type: "datetime",
    },
  },
  {
    // Add collections here if needed
  },
  {
    // Add references here if needed
    accountUser: reference<AccountUser>(() => AccountUser, "account_user_id"),
  },
);

interface CreditTransaction extends ModelAttributes<typeof config> {}

@model("credit_transactions", config)
class CreditTransaction extends Model<typeof config> {}

export default CreditTransaction;
