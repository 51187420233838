import { ApplicationView } from "@mixitone/mvc";
import { PropsWithChildren } from "react";
import React from "react";

export type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
  htmlFor?: string;
  icon?: React.ReactElement<{ className?: string }>;
  optional?: boolean;
  className?: string;
};

const ControlledLabel: React.FC<PropsWithChildren<LabelProps>> = ({
  children,
  icon,
  htmlFor,
  className,
  optional,
  ...props
}) => {
  const iconEl = icon
    ? React.cloneElement(icon, { className: `h-[18px] mr-1 ${icon.props["className"]}` })
    : null;

  return (
    <label
      htmlFor={htmlFor}
      className={`flex items-center justify-between text-gray-700 ${className}`}
      {...props}
    >
      <span>
        {children}
        {optional && <span className="text-sm text-gray-500"> (optional)</span>}
      </span>
      {iconEl}
    </label>
  );
};

const Label = ApplicationView(ControlledLabel);
export { Label };
