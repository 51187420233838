import { Brand } from "@mixitone/components";
import { ApplicationView } from "@mixitone/mvc";
import { ErrorBoundary } from "@sentry/react";
import clsx from "clsx";
import { useTitle } from "components/hooks/useTitle";
import tauri from "lib/tauri";
import React from "react";
import OfflineNotification from "./OfflineNotification";
import TopNav from "./TopNav/TopNav";

const wwwUrl = import.meta.env.VITE_WWW;

const Layout: React.FC<{
  title?: string;
  menu?: React.ReactNode;
  children: React.ReactNode;
}> = ({ menu, title, children }) => {
  useTitle(title);

  return (
    <>
      <div
        data-tauri-drag-region
        className="mixitone-header flex w-full select-none items-center justify-center border-b border-b-slate-900 bg-slate-800 px-5"
      >
        {!tauri.enabled && (
          <a href={wwwUrl}>
            <Brand color="dark" className="text-scale-1200 text-xl sm:text-xl lg:text-2xl xl:text-3xl" />
          </a>
        )}
        {tauri.enabled && (
          <Brand
            color="dark"
            className="text-scale-1200 pointer-events-none absolute top-5 mx-auto w-[200px] text-xl sm:text-3xl md:text-center"
          />
        )}
        <div
          data-tauri-drag-region
          className={clsx("flex w-full items-center justify-end", {
            "pr-2": !tauri.enabled,
          })}
        >
          {menu}
          <TopNav />
        </div>
      </div>
      <ErrorBoundary fallback={<div className="p-5">Error loading {title}</div>}>{children}</ErrorBoundary>
      {tauri.enabled && <OfflineNotification />}
    </>
  );
};

export default ApplicationView(Layout);
