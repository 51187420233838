import SplitScreenLayout from "components/Layout/SplitScreenLayout";
import TauriController from "controllers/TauriController";
import { ApplicationView } from "@mixitone/mvc";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Sync: React.FC = () => {
  const controller = TauriController.use();
  const { syncProgress, syncTableProgress } = controller.state;

  const { total, progress } = syncProgress;
  const pctPerTable = 100 / total;
  const tableProgress = syncTableProgress ? syncTableProgress.done / syncTableProgress.total : 0.0;

  let useProgress = progress;
  if (!isNaN(pctPerTable) && !isNaN(tableProgress)) {
    useProgress = Math.round(progress + pctPerTable * tableProgress);
  }

  return (
    <SplitScreenLayout title="Syncing your account" showBack={false}>
      <div>
        <p className="text-gray-900">Your account is being synced. This may take a few moments.</p>
        <div style={{ width: 128, height: 128 }}>
          <CircularProgressbarWithChildren value={useProgress} text={`${useProgress}%`} />
        </div>
      </div>
    </SplitScreenLayout>
  );
};

export default ApplicationView(Sync);
