import ApplicationController from "@mixitone/mvc";
import tauri from "lib/tauri";
import { listen } from "lib/tauri/MixitoneEvent";
import SessionController from "./SessionController";
import { SubscriptionManager } from "@mixitone/oom/SubscriptionManager";

interface State {
  syncState: "first_sync" | "idle" | "starting" | "syncing" | "updating" | "finished" | "offline";
  syncProgress: {
    total: number;
    remaining: number;
    progress: number;
  };
  syncTableProgress?: { table: string; total: number; done: number };
  syncError?: string;

  uploadState: "idle" | "uploading";
  uploadProgress?: { count: number; index?: number };
  rowsToSync: number;
}

interface ProgressEvent {
  count: number;
  index?: number;
  rows_left: number;
}

interface StartEvent {
  count: number;
}
type FinishedEvent = { count: number; rows_left: number };

interface ChangeEvent {
  row: Record<string, any>;
  update: {
    row_id: string;
    source: "sync" | "client";
    table_name: string;
    update_type: "Insert" | "Update" | "Delete";
  };
}

const finishedTimeout = import.meta.env.VITE_TEST === "1" ? 0 : 5000;

class TauriController extends ApplicationController<State> {
  signedInAtSync?: boolean;

  override async initialize(props: unknown) {
    this.signedInAtSync = this.get(SessionController).signedIn;
    this.state.syncState = "idle";
    this.state.syncProgress = {
      total: 0,
      remaining: 0,
      progress: 0,
    };
    this.state.uploadState = "idle";

    listen("mixitone:sync:started", () => {
      this.debug("sync_started");
      this.state.syncProgress = { total: 0, remaining: 0, progress: 0 };
      this.state.syncError = undefined;

      if (!this.signedInAtSync) {
        this.state.syncState = "first_sync";
      } else {
        this.state.syncState = "starting";
      }
    });
    listen("mixitone:sync:changes", (event) => {
      this.debug("sync_changes", event);
      this.state.syncState = "syncing";
      this.state.rowsToSync = event.payload.total;
    });
    listen("mixitone:sync:progress", (event) => {
      this.debug("sync_progress", event);
      const { total, remaining } = event.payload as { total: number; remaining: number };
      this.state.syncState = "syncing";
      this.state.syncProgress = {
        total,
        remaining,
        progress: Math.round(((total - remaining) / total) * 100),
      };
      this.state.syncTableProgress = undefined;
    });
    listen("mixitone:sync:table", (event) => {
      this.debug("sync_table", event);
      this.state.syncState = "syncing";
      const { total, done, table_name } = event.payload;
      this.state.syncTableProgress = { total, done, table: table_name };
    });
    listen("mixitone:sync:update", () => {
      this.debug("sync_update");
      this.state.syncState = "updating";
    });
    listen("mixitone:sync:finished", (event) => {
      this.debug("sync_finished", event);
      this.state.syncState = "finished";
      this.state.syncProgress.remaining = 0;
      this.state.syncProgress.progress = 100;
      this.state.syncTableProgress = undefined;

      setTimeout(() => {
        if (this.state.syncState === "finished") {
          this.state.syncState = "idle";
        }
      }, finishedTimeout);
    });
    listen("mixitone:sync:error", (event) => {
      this.debug("sync_error", event);
      this.state.syncState = "idle";
      this.state.syncError = event.payload.message;
    });
    listen("mixitone:sync:offline", () => {
      this.debug("sync_offline");
      this.state.syncState = "offline";
      this.state.syncError = "You are offline. Please check your internet connection.";
    });
    tauri.listen<StartEvent>("mixitone:upload:started", (event) => {
      this.debug("started", event);
      this.state.uploadState = "uploading";
      this.state.uploadProgress = { count: event.payload.count };
      this.state.rowsToSync = event.payload.count;
    });
    tauri.listen<FinishedEvent>("mixitone:upload:finished", (event) => {
      this.debug("finished", event);
      this.state.uploadState = "idle";
      this.state.uploadProgress = { count: event.payload.count };
      this.state.rowsToSync = event.payload.rows_left;
    });
    tauri.listen<ProgressEvent>("mixitone:upload:progress", (event) => {
      this.debug("progress", event);
      this.state.uploadState = "uploading";
      this.state.uploadProgress = { count: event.payload.count, index: event.payload.index };
      this.state.rowsToSync = event.payload.rows_left;
    });

    tauri.listen<ChangeEvent>("mixitonedb:change", (event) => {
      this.handleChange(event.payload);
    });

    this.observe(() => {
      // @ts-ignore
      window.tauriUploadState = this.state.uploadState;
      // @ts-ignore
      window.tauriSyncState = this.state.syncState;
    });
  }

  get syncing() {
    return ["first_sync", "syncing"].includes(this.state.syncState);
  }

  get ready() {
    return ["idle", "syncing"].includes(this.state.syncState);
  }

  handleChange(event: ChangeEvent) {
    let updateType: Parameters<typeof SubscriptionManager.prototype.emit>[1] = "UPDATE";
    const { row, update } = event;

    switch (update.update_type) {
      case "Insert":
        updateType = "INSERT";
        break;
      case "Update":
        updateType = "UPDATE";
        break;
      case "Delete":
        updateType = "DELETE";
        break;
    }

    SubscriptionManager.instance.emit(
      update.table_name,
      updateType,
      row,
      updateType === "DELETE" ? row : undefined,
    );
  }
}

export default TauriController;
