import FromNow from "@mixitone/components/Controls/FromNow";
import { FeedbackMessage } from "@mixitone/models";
import { ApplicationView } from "@mixitone/mvc";
import clsx from "clsx";
import { FeedbackMessagesController } from "./FeedbackMessagesController";
import { useEffect } from "react";

const MessageRow = ({ message }: { message: FeedbackMessage }) => {
  const controller = FeedbackMessagesController.use();
  const isUserMessage = message.from_user;

  useEffect(() => {
    if (!isUserMessage && message.states.length === 0) {
      controller.actionMarkMessageViewed(message.id!);
    }
  }, []);

  return (
    <div className={clsx("flex", { "justify-end": !isUserMessage })}>
      <div>
        <div className={clsx("relative", { "opacity-50": message.sending })}>
          <div
            className={clsx("rounded-lg p-3", {
              "bg-gray-200": isUserMessage,
              "bg-brand-100": !isUserMessage,
            })}
          >
            <div>{message.message}</div>
          </div>
          <div
            className={clsx("absolute -bottom-2 h-0 w-0", {
              "left-4 border-l-[8px] border-r-[8px] border-t-[8px] border-l-transparent border-r-transparent border-t-gray-200":
                isUserMessage,
              "right-4 border-l-[8px] border-r-[8px] border-t-[8px] border-l-transparent border-r-transparent border-t-brand-100":
                !isUserMessage,
            })}
          ></div>
        </div>
        <div
          className={clsx("mt-3 space-x-1 text-xs text-gray-600", {
            "ml-[12px]": isUserMessage,
            "mr-[12px] text-right": !isUserMessage,
          })}
        >
          <span>{isUserMessage ? "You" : message.admin_name}</span>
          <span className="text-gray-400">•</span>
          {message.sending && <span>sending</span>}
          {message.created_at && !message.sending && <FromNow date={message.created_at} />}
        </div>
      </div>
    </div>
  );
};

export default ApplicationView(MessageRow);
