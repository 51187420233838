import { observe, unobserve } from "@nx-js/observer-util";
import React, { memo, useCallback, useEffect, useMemo, useSyncExternalStore } from "react";

function isReactFunction<P>(
  component: React.ComponentType<P>,
): component is React.FC<P> | ((props: P) => React.ReactElement) {
  return !component.prototype?.isReactComponent;
}

type ReactComponent<T> = React.ComponentClass<T, any> | React.FC<T> | ((props: T) => React.ReactElement);

class ViewUpdateEmitter {
  static batching = false;
  static instances: WeakRef<ViewUpdateEmitter>[] = [];
  static batchedInstances: WeakRef<ViewUpdateEmitter>[] = [];

  static updateAll() {
    ViewUpdateEmitter.instances.forEach((ref) => {
      const instance = ref.deref();
      if (instance) {
        instance.queueIfUpdate();
      }
    });
  }

  static resolveBatches() {
    const dedupe = new Set<ViewUpdateEmitter>();
    ViewUpdateEmitter.batchedInstances.forEach((ref) => {
      const instance = ref.deref();
      if (instance) {
        dedupe.add(instance);
      }
    });
    ViewUpdateEmitter.batchedInstances = [];
    for (const instance of dedupe) {
      instance.queueIfUpdate();
    }
  }

  constructor() {
    ViewUpdateEmitter.instances.push(new WeakRef(this));
  }

  callback?: Function = undefined;
  // this is used to trigger the update when the callback is set if there was no
  // callback at the time. Otherwise there can be times when the react component
  // has run the useEffect return to remove the callback because its remounting
  // or whatever, and so it won't get the update.
  hasUpdate = false;
  queued = false;

  on(callback: Function) {
    this.callback = callback;
    this.queueIfUpdate();
  }

  off() {
    this.callback = undefined;
  }

  queueIfUpdate() {
    if (this.hasUpdate) {
      this.queue();
    }
  }

  queue() {
    if (this.queued) return;

    if (ViewUpdateEmitter.batching) {
      this.hasUpdate = true;
      ViewUpdateEmitter.batchedInstances.push(new WeakRef(this));
      return;
    }

    queueMicrotask(() => {
      this.queued = false;

      if (this.callback) {
        this.hasUpdate = false;
        this.callback();
      } else {
        this.hasUpdate = true;
      }
    });
    this.queued = true;
  }
}

interface Options {
  debugger?: Function;
}

/**
 * Prevent updates to all views until the batch is complete.
 */
export async function batch<T>(fn: () => Promise<T>): Promise<T> {
  ViewUpdateEmitter.updateAll();

  ViewUpdateEmitter.batching = true;
  const result = await fn();

  ViewUpdateEmitter.batching = false;
  ViewUpdateEmitter.resolveBatches();
  return result;
}

/**
 * Wrap a React component in a reactive view.
 */
export function ApplicationView<T>(Comp: ReactComponent<T>, options?: Options) {
  let ReactiveComp: React.ComponentType<T>;

  if (!isReactFunction(Comp)) {
    throw new Error("ApplicationView only supports function components");
  }

  let snapshot = { count: 0 };
  let getSnapshot = () => snapshot;

  // use a hook based reactive wrapper when we can
  ReactiveComp = (props: T) => {
    const emitter = new ViewUpdateEmitter();

    // create a memoized reactive wrapper of the original component (render)
    // at the very first run of the component function
    const render = useMemo(
      () => {
        return observe(Comp, {
          scheduler: () => {
            emitter.queue();
          },
          lazy: true,
        });
      },
      // Adding the original Comp here is necessary to make React Hot Reload work
      // it does not affect behavior otherwise
      [Comp],
    );

    const subscribe = useCallback((onStoreChange: Function) => {
      emitter.on(() => {
        if (options?.debugger) {
          options.debugger("update triggered");
        }
        snapshot = { count: snapshot.count + 1 };
        onStoreChange();
      });

      return () => {
        emitter.off();
        unobserve(render);
      };
    }, []);

    useSyncExternalStore(subscribe, getSnapshot);

    // cleanup the reactive connections after the very last render of the component
    useEffect(() => {
      return () => {
        emitter.off();
        // We don't need to trigger a render after the component is removed.
        unobserve(render);
      };
    }, []);

    // run the reactive render instead of the original one
    return render(props);
  };

  if ("displayName" in Comp) {
    ReactiveComp.displayName = Comp.displayName || Comp.name || "Unknown";
  } else {
    ReactiveComp.displayName = Comp.name || "Unknown";
  }

  // Copy static props
  Object.keys(Comp).forEach((key) => {
    // @ts-ignore
    ReactiveComp[key] = Comp[key];
  });

  return memo(ReactiveComp);
}
