import { animateFadeTo, LoadingModal, LoadingScreen } from "@mixitone/components";
import { ApplicationView, chainScopes } from "@mixitone/mvc";
import Layout from "components/Layout/Layout";
import RouteController from "controllers/RouteController";
import { ErrorBoundary } from "ErrorBoundary";
import Messages, { MessagesController } from "Messages";
import React, { useEffect } from "react";
import { flushSync } from "react-dom";
import { AppController } from "./controllers/AppController";
import FeedbackMessagesButton from "components/Layout/FeedbackMessages/FeedbackMessagesButton";
import { AccountUser } from "@mixitone/models";

interface DisplayedComponent {
  path: string;
  element: React.ReactNode;
}

const PageCopmonentWrapper = ({
  animate,
  Component,
  segments,
}: {
  animate: (value: unknown) => void;
  Component: React.ComponentType<any>;
  segments: any;
}) => {
  useEffect(() => {
    animate(1);
  }, []);

  // @ts-ignore
  return <Component {...segments} />;
};

const App: React.FC<{}> = () => {
  const controller = AppController.use();
  const routeController = RouteController.use();
  const { loading } = controller.state;
  const { pageComponents } = routeController.state;
  const [displayedComponents, setDisplayedComponents] = React.useState([] as DisplayedComponent[]);

  useEffect(() => {
    const newDisplayedComponents = [] as DisplayedComponent[];
    const { promise: animatePromise, resolve: animate } = Promise.withResolvers();
    const timeoutPromise = new Promise((resolve) => setTimeout(resolve, 500));

    for (let i = pageComponents.length - 1; i >= 0; i--) {
      const pageComponent = pageComponents[i];
      const prevElement = displayedComponents.find(
        (component) => component.path === pageComponent.path,
      )?.element;
      const fallback = newDisplayedComponents.length === 0 ? <LoadingScreen /> : <LoadingModal title={""} />;

      const element = prevElement || (
        <React.Suspense fallback={fallback} key={`${pageComponent.path}`}>
          <PageCopmonentWrapper
            animate={animate}
            Component={pageComponent.component}
            segments={pageComponent.segments}
          />
          {/* <pageComponent.component {...pageComponent.segments} /> */}
        </React.Suspense>
      );

      newDisplayedComponents.push({ path: pageComponent.path, element });
    }

    if (document.startViewTransition) {
      document.startViewTransition(() => {
        flushSync(() => {
          setDisplayedComponents(newDisplayedComponents);
        });

        return Promise.race([timeoutPromise, animatePromise]);
      });
    } else {
      // If startViewTransition is not available, we can simulate a smooth transition
      // by fading out the old view and fading in the new view.
      if (pageComponents.length > 0) {
        const root = document.querySelector("#root");
        const copy = root?.cloneNode(true) as HTMLDivElement;
        copy.style.position = "absolute";
        copy.style.top = "0";
        copy.style.left = "0";
        copy.style.pointerEvents = "none";
        copy.style.zIndex = "1000";
        document.body.appendChild(copy);

        // If we're showing a component on top of another then use a longer fade
        const duration = pageComponents.length > 1 || displayedComponents.length > 1 ? 150 : 50;

        Promise.race([timeoutPromise, animatePromise]).then(() => {
          animateFadeTo(copy, 1, 0, { duration, delay: 50 }).then(() => {
            document.body.removeChild(copy);
          });
        });
      }

      setDisplayedComponents(newDisplayedComponents);
    }
  }, [pageComponents]);

  if (loading || !pageComponents.length) {
    return (
      <Layout>
        <LoadingScreen />
      </Layout>
    );
  }

  return (
    <React.Suspense fallback={<LoadingScreen />}>
      <ErrorBoundary path={displayedComponents.map((d) => d.path).join("/")}>
        {displayedComponents.map((component) => component.element)}
      </ErrorBoundary>
      {AccountUser.current && (
        <React.Suspense>
          <FeedbackMessagesButton />
        </React.Suspense>
      )}
      <Messages />
    </React.Suspense>
  );
};

if (import.meta.hot) {
  import.meta.hot.on("vite:beforeUpdate", () => {
    console.log("beforeUpdate");
  });
}

const scopes: Parameters<typeof chainScopes>[0] = [RouteController, MessagesController, AppController];
export default chainScopes(scopes, ApplicationView(App));
