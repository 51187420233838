import { Account, AccountUser, FeedbackMessage, UserFeedbackMessagesState } from "@mixitone/models";
import { observe } from "@mixitone/mvc";
import { List, subscribeList } from "@mixitone/oom";
import { withStateToggle } from "@mixitone/util/concerns";
import { tryJsonParse } from "@mixitone/util/util";
import { AppController } from "controllers/AppController";
import MixitoneController from "controllers/MixitoneController";
import SessionController from "controllers/SessionController";
import tauri from "lib/tauri";
import { MessagesController } from "Messages";

interface Props {}

interface State {
  loading: boolean;
  newMessages: boolean;
  messages: List<FeedbackMessage>;
  messageBeingSent?: FeedbackMessage;
  showMessages: boolean;
  showForm: boolean;
  messageText: string;
  error?: string;
  sending: boolean;
}

export class FeedbackMessagesController extends MixitoneController<State, Props> {
  markedViewed: Set<string> = new Set();

  get initialState(): State {
    return {
      loading: true,
      messages: new List(),
      newMessages: false,
      showMessages: false,
      showForm: false,
      messageText: "",
      sending: false,
    };
  }

  async initialize(props: Props) {
    this.state.messageText = "";

    this.observe(() => {
      this.state.showForm = !this.state.loading && this.state.messages.length === 0;

      this.state.newMessages = this.state.messages.some((message) => {
        return !message.from_user && message.states.every((state) => state.viewed_at === null);
      });
    });

    await this.loadMessages();
  }

  @withStateToggle("loading")
  async loadMessages() {
    const query = FeedbackMessage.query().limit(50).orderBy("created_at", "asc").preload("states");
    const messages = await query.all();
    this.state.messages = new List(...messages);
    this.addDependency(await subscribeList(this.state.messages, query));
  }

  async actionMarkMessageViewed(id: string) {
    if (this.markedViewed.has(id)) return;
    this.markedViewed.add(id);

    const state = new UserFeedbackMessagesState({
      feedback_message_id: id,
      viewed_at: new Date().toISOString(),
      user_id: AccountUser.current?.user_id,
    });
    this.state.messages.findById(id)?.states.push(state);

    await state.save();
  }

  actionSetMessageText(text: string) {
    this.state.messageText = text;
  }

  @withStateToggle("sending", { errorPropertyKey: "error" })
  async actionSendMessage() {
    const previousMessage = this.state.messages[this.state.messages.length - 1];
    const message = new FeedbackMessage({
      from_user: true,
      message: this.state.messageText,
      created_at: new Date().toISOString(),
    });
    this.state.messageText = "";

    if (previousMessage) {
      message.email = previousMessage.email;
      message.reply_to_id = previousMessage.id;
    }

    const appController = this.get(AppController);
    this.state.messageBeingSent = message;

    const currentLength = this.state.messages.length;
    const observer = observe(() => {
      if (this.state.messages.length !== currentLength) {
        this.state.messageBeingSent = undefined;
        observer();
      }
    });

    const adapter = this.get(SessionController).adapter;

    try {
      const response = tryJsonParse(
        await adapter.edgeFunction("feedback", {
          action: "sendFeedback",
          account_id: Account.current?.id,
          club_name: appController.state.currentClub?.name,
          email: previousMessage?.email,
          reply_to_id: previousMessage?.id,
          message: message.message!,
        }),
      ) as any;

      if (response && "error" in response && response.error) {
        throw new Error(response.error);
      }
    } catch (err) {
      this.state.messageBeingSent = undefined;
      this.get(MessagesController).actionAddMessage(
        "Sorry, there was an error sending your message.",
        "error",
      );
      console.error(err);
      throw err;
    }

    if (tauri.enabled) {
      await tauri.performSync();
    }
  }

  actionShowMessages(show: boolean) {
    this.state.showMessages = show;
  }
}
