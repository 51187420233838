import CloseIcon from "@mixitone/components/icons/close.svg";
import { ApplicationView } from "@mixitone/mvc";
import clsx from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  title: React.ReactNode;
  testId?: string;
  className?: string;
  onClose: () => void;
}

const Popup: React.FC<React.PropsWithChildren<Props>> = ({ title, className, testId, onClose, children }) => {
  const [ready, setReady] = useState(false);
  const popupEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    requestAnimationFrame(() => {
      setReady(true);
    });
  });

  const handleClose = useCallback(() => {
    if (!popupEl.current) return;

    if ("animate" in popupEl.current) {
      const anim = popupEl.current.animate(
        [{ transform: "translateY(0)" }, { transform: "translateY(100%)" }],
        {
          duration: 200,
          fill: "forwards",
        },
      );
      anim.onfinish = () => {
        onClose();
      };
      anim.play();
    } else {
      onClose();
    }
  }, [onClose]);

  return (
    <div
      data-testid={testId}
      ref={popupEl}
      className={twMerge(
        clsx(
          "fixed bottom-4 right-4 w-80 rounded-lg bg-white opacity-0 shadow-xl transition-all duration-200",
          {
            "opacity-0": !ready,
            "opacity-100": ready,
            "translate-y-[100%]": !ready,
            "translate-y-0": ready,
          },
        ),
        className,
      )}
    >
      <h3 className="flex items-center justify-between rounded-t-lg bg-slate-800 p-4 text-lg font-semibold text-white">
        <span>{title}</span>
        <div onClick={handleClose} title="Close" className="cursor-pointer">
          <CloseIcon className="w-[30px] fill-white transition-transform hover:scale-125 sm:w-[19px]" />
        </div>
      </h3>
      {children}
    </div>
  );
};

export default ApplicationView(Popup);
