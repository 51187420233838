import { Model, ModelAttributes, ModelFields, model, modelConfig, onWrite } from "@mixitone/oom";

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    admin_club: {
      type: "boolean",
    },
    club_id: {
      type: "string",
    },
    created_at: {
      type: "datetime",
    },
    is_deleted: {
      type: "boolean",
    },
    manage_club: {
      type: "boolean",
    },
    manage_sessions: {
      type: "boolean",
    },
    updated_at: {
      type: "datetime",
    },
    user_id: {
      type: "string",
    },
    view_club: {
      type: "boolean",
    },
  },
  {
    // Add collections here if needed
  },
  {
    // Add references here if needed
  },
);

interface ClubPermission extends ModelAttributes<typeof config> {}

const permissionRequirements: Partial<
  Record<keyof ModelFields<typeof config>, Array<keyof ModelFields<typeof config>>>
> = {
  admin_club: ["view_club"],
  manage_club: ["view_club"],
  manage_sessions: ["view_club", "manage_club"],
};

export type PermissionKey = "admin_club" | "manage_club" | "manage_sessions" | "view_club";

@model("club_permissions", config)
class ClubPermission extends Model<typeof config> {
  static PermissionKeys = ["admin_club", "manage_club", "manage_sessions", "view_club"] as PermissionKey[];

  @onWrite("admin_club", "manage_club", "manage_sessions", "view_club")
  checkRequirements(key: keyof ModelFields<typeof config>) {
    if (this[key]) {
      const requirements = permissionRequirements[key];
      if (!requirements) return;

      for (const requirement of requirements) {
        if (!this[requirement]) {
          this.writeAttribute(requirement, true);
        }
      }
    } else {
      for (const [permission, requirements] of Object.entries(permissionRequirements)) {
        if (requirements.includes(key)) {
          this.writeAttribute(permission as any, false);
        }
      }
    }
  }
}

export default ClubPermission;
