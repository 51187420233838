import { EventCallback } from "@tauri-apps/api/event";
import tauri from "./";

namespace MixitoneEvent {
  export type UploadStarted = {
    count: number;
  };
  export type UploadProgress = {
    count: number;
    index?: number;
    rows_left: number;
  };
  export type UploadFinished = {
    count: number;
    rows_left: number;
  };

  export type SyncStarted = {};
  export type SyncChanges = {
    total: number;
  };
  export type SyncProgress = {
    total: number;
    remaining: number;
  };
  export type SyncFinished = {
    total: number;
    tables: Record<string, number>;
  };
  export type SyncError = {
    message: string;
  };
  export type SyncUpdate = {};
  export type SyncOffline = {};
  export type SyncTable = {
    table_name: string;
    total: number;
    done: number;
  };
}

type MixitoneEvents = {
  "mixitone:upload:started": MixitoneEvent.UploadStarted;
  "mixitone:upload:progress": MixitoneEvent.UploadProgress;
  "mixitone:upload:finished": MixitoneEvent.UploadFinished;

  "mixitone:sync:started": MixitoneEvent.SyncStarted;
  "mixitone:sync:changes": MixitoneEvent.SyncChanges;
  "mixitone:sync:progress": MixitoneEvent.SyncProgress;
  "mixitone:sync:finished": MixitoneEvent.SyncFinished;
  "mixitone:sync:error": MixitoneEvent.SyncError;
  "mixitone:sync:offline": MixitoneEvent.SyncOffline;
  "mixitone:sync:update": MixitoneEvent.SyncUpdate;
  "mixitone:sync:table": MixitoneEvent.SyncTable;
};

export function listen<T extends keyof MixitoneEvents>(event: T, callback: EventCallback<MixitoneEvents[T]>) {
  return tauri.listen<MixitoneEvents[T]>(event, callback) as Promise<() => void>;
}
