import { Model, ModelAttributes, model, modelConfig } from "@mixitone/oom";
import { setAccountId } from "./concerns/SetAccountId";

const config = modelConfig(
  {
    created_at: {
      type: "datetime",
    },
    email: {
      type: "string",
    },
    mobile: {
      type: "string",
    },
    name: {
      type: "string",
    },
    updated_at: {
      type: "datetime",
    },
    user_id: {
      type: "string",
    },
  },
  {},
  {},
);

interface PlayerProfile extends ModelAttributes<typeof config> {}

@setAccountId
@model("player_profiles", config)
class PlayerProfile extends Model<typeof config> {}

export default PlayerProfile;
