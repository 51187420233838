import Maybe from "@mixitone/maybe";
import { Account, AccountUser, Club, ClubPermission } from "@mixitone/models";
import { ApplicationController, batch } from "@mixitone/mvc";
import { List, subscribeList } from "@mixitone/oom";
import { isNil } from "@mixitone/util";
import * as Sentry from "@sentry/react";
import { WebviewWindow } from "@tauri-apps/api/window";
import actionLogger from "lib/actionLogger";
import { User } from "lib/session/SessionAdapter";
import tauri from "lib/tauri";
import RouteController from "./RouteController";
import SessionController from "./SessionController";
import { routeHelpers } from "./routes";

interface State {
  online: boolean;
  loading: boolean;
  creatingAccount: boolean;
  token: string | null;
  error: string | null;
  accounts: List<Account>;
  clubs: List<Club>;
  currentClub: Club | null;

  updateAvailable?: boolean;
  updateVersion?: string;
}

interface Props {
  ready?: boolean;
}

export interface AppController {
  titles: string[];
}

@actionLogger
export class AppController extends ApplicationController<State, Props> {
  static override initialState: State = {
    online: true,
    loading: false,
    creatingAccount: false,
    token: null,
    error: null,
    accounts: new List(),
    clubs: new List(),
    currentClub: null,
  };

  titles = this.observable([] as string[]);
  checkForUpdatesInterval?: ReturnType<typeof setInterval>;

  override async initialize(props: {}) {
    window.addEventListener("online", this.handleOnline);
    window.addEventListener("offline", this.handleOnline);
    this.handleOnline();

    this.observeTitle();
    this.observeLoginState();
    this.observeCurrentClub();

    if (tauri.enabled) {
      // Intercept all clicks and forward them through the route controller when
      // tauri is enabled
      const clickHandler = (event: MouseEvent) => {
        if (event.target instanceof Element) {
          if (event.target.matches("a") || event.target.closest("a")) {
            const href = event.target.closest("a")?.getAttribute("href") || event.target.getAttribute("href");
            if (href && !href?.includes("http") && !href?.startsWith("#")) {
              event.preventDefault();
              this.get(RouteController).actionForward(href);
            }
          }
        }
      };
      document.addEventListener("click", clickHandler);
      this.addDependency(() => {
        document.removeEventListener("click", clickHandler);
      });

      this.checkForUpdatesInterval = setInterval(
        () => {
          this.checkForUpdates();
        },
        1000 * 60 * 5,
      );
      this.addDependency(() => {
        clearInterval(this.checkForUpdatesInterval!);
      });
    }
  }

  override destroy() {
    window.removeEventListener("online", this.handleOnline);
    window.removeEventListener("offline", this.handleOnline);
  }

  handleOnline = () => {
    this.state.online = navigator.onLine;
    this.checkForUpdates();
  };

  get sessionController() {
    return this.get(SessionController);
  }

  get routeController() {
    return this.get(RouteController);
  }

  get isOwner() {
    return Account.current?.user_id === this.sessionController.user?.id;
  }

  async checkForUpdates() {
    if (!this.state.online) return;
    if (!tauri.enabled) return;

    try {
      const updates = await tauri.checkUpdate();
      this.state.updateAvailable = updates.shouldUpdate;
      this.state.updateVersion = updates.manifest?.version;
    } catch (err) {
      console.error(err);
      Sentry.captureException(err, { extra: { context: "AppController.checkForUpdates" } });
    }
  }

  _loadingForUserId?: string;
  observeLoginState() {
    this.observe(() => {
      if (this.sessionController.signedIn) {
        if (AccountUser.current?.user_id !== this.sessionController.user?.id) {
          if (this._loadingForUserId === this.sessionController.user?.id) return;
          this._loadingForUserId = this.sessionController.user?.id;

          this.loadAccount().then(() => {
            this._loadingForUserId = undefined;

            if (this.routeController.urlAfterLogin) {
              this.debug("forwarding to url after login");
              this.routeController.actionForward(this.routeController.urlAfterLogin);
              this.routeController.urlAfterLogin = null;
            } else if (["/", routeHelpers.login({})].includes(this.routeController.state.url)) {
              this.actionReturnOrHome();
            }
          });
          return;
        }
      } else {
        this.resetAccountState();
      }

      if (this.routeController.state.url === "/") {
        if (this.sessionController.signedIn) {
          this.debug("home handling, return or home");
          this.actionReturnOrHome();
        } else {
          this.debug("home handling, forward to login");
          this.routeController.actionForward(routeHelpers.login({}));
        }
      }
    });
  }

  observeCurrentClub() {
    this.observe(() => {
      const url = this.routeController.state.url;
      const match = url.match(/clubs\/([^/]+)/);
      if (match && this.state.clubs?.findById) {
        const clubId = match[1];
        const club = this.state.clubs.findById(clubId);
        if (club) {
          this.actionSetCurrentClub(club, false);
        }
      }
    });
  }

  async actionSignOut() {
    batch(async () => {
      this.resetAccountState();
      this.debug("log out");
      await this.sessionController.actionSignOut();
      await this.routeController.actionForward(routeHelpers.login({}));
    });
  }

  /**
   * Reset important state to null when the user changes or signs out
   */
  resetAccountState() {
    this.state.accounts = new List(...[]);
    this.state.clubs = new List();
    this.state.currentClub = null;

    AccountUser.current = null;
  }

  async loadAccount() {
    const user = this.sessionController.user;
    if (user?.id === AccountUser.current?.user_id) return;

    this.state.loading = true;
    this.resetAccountState();
    if (!user?.id) {
      this.state.loading = false;
      return;
    }

    try {
      const accountUsers = await AccountUser.forUser(this.sessionController.user?.id!);
      if (accountUsers.length === 0 && user.is_anonymous) {
        throw new Error("No account users found for anonymous user");
      } else if (accountUsers.length === 0) {
        await this.createAccount();
      } else {
        await this.setCurrentAccountUser(accountUsers[0]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.state.loading = false;
    }
  }

  async createAccountUser(user: User) {
    let account_id: string;
    const adapter = this.sessionController.adapter;

    const { error, data } = await adapter.rpc<{ id: string }>("insert_trial_account", {
      uid: user.id,
    });
    if (error && error.message === "User already has an account") {
      const account = await Account.query().eq("user_id", user.id).first();
      if (!account) throw new Error("User already has an account but could not find it");
      account_id = account.id!;
    } else if (error) {
      if (error) throw error;
    } else if (!data.id) {
      throw new Error("No account id returned from insert_trial_account");
    } else {
      account_id = String(data.id);
    }

    const accountUsers = await AccountUser.forUser(user.id);
    const accountUser = accountUsers.find((au) => au.account_id === String(account_id));
    if (!accountUser) throw new Error("Could not find account user for newly created account");

    if (isNil(accountUser.name)) {
      accountUser.name = user.user_metadata?.name || "Admin";
      await accountUser.save();
    }

    return accountUser;
  }

  async createAccount() {
    const user = this.sessionController.user;
    if (!user) return;

    this.state.creatingAccount = true;

    try {
      const accountUser = await this.createAccountUser(user);
      await this.setCurrentAccountUser(accountUser);
    } catch (err) {
      console.error(err);
      await this.actionSignOut();
    } finally {
      this.state.creatingAccount = false;
    }
  }

  async setCurrentAccountUser(accountUser: AccountUser) {
    Sentry.setUser({ id: accountUser?.id });

    AccountUser.current = accountUser;
    if (isNil(accountUser) || isNil(accountUser.account?.id)) return;

    const account = Account.current;
    if (isNil(account)) return;

    this.resolveDependency("loadClubs");
    await Promise.all([account.clubs.load(), accountUser.loadClubPermissions()]);

    // Listen for changes to the account user's permissions and reload
    this.addDependency(
      "loadClubPermissions",
      await ClubPermission.query()
        .eq("user_id", accountUser.user_id)
        .subscribe(() => {
          accountUser.loadClubPermissions();
        }),
    );

    this.state.clubs = new List(...account.clubs);
    this.state.clubs.orderBy("name");
    this.addDependency("loadClubs", await subscribeList(this.state.clubs, account.clubs.query));

    const currentClub = Maybe(window.localStorage.getItem("currentClub"))
      .tryMap<string, Error>(
        (value) => JSON.parse(value),
        (err) => {
          this.debug("Error parsing current club", err);
        },
      )
      .map((clubId) => this.state.clubs.findById(clubId))
      .getOrElse(() => this.state.clubs[0]);

    if (currentClub) {
      await this.actionSetCurrentClub(currentClub, false);
    }
  }

  async actionSetCurrentClub(club: Club, navigate: boolean = true) {
    this.state.currentClub = club;
    localStorage.setItem("currentClub", JSON.stringify(club.id));

    if (navigate && !window.location.href.endsWith(`clubs/${club.id}`)) {
      await this.routeController.actionForward(routeHelpers.club({ clubId: club.id! }));
    }
  }

  async actionReturnOrHome() {
    if (Account.current) {
      if (this.state.currentClub) {
        this.debug("forward to current club");
        await this.routeController.actionForward(routeHelpers.club({ clubId: this.state.currentClub.id! }));
        return;
      } else if (this.state.clubs[0]) {
        this.debug("forward to first club");
        this.actionSetCurrentClub(this.state.clubs[0]);
        return;
      } else {
        this.debug("forward to account");
        await this.routeController.actionForward(routeHelpers.account({}));
        return;
      }
    }

    this.debug("no account, forward to /not_found");
    await this.routeController.actionForward(routeHelpers.notFound({}));
  }

  observeTitle() {
    this.observe(() => {
      const title = ["Mixitone", ...this.titles.filter((t) => t && t.length > 0)].join(" | ");
      if (title.length > 0) {
        document.title = title;
      }
    });
  }

  actionAppendTitle(title: string) {
    this.titles.push(title);
    return this.titles.length - 1;
  }

  actionRemoveTitle(title: string, index: number) {
    if (this.titles[index] === title) {
      if (index === this.titles.length - 1) {
        this.titles.splice(index, 1);
      } else {
        this.titles[index] = "";
      }
    }
  }

  async actionCheckForUpdates() {
    const webview = new WebviewWindow("updater2", {
      url: "updater/updater.html",
      titleBarStyle: "overlay",
      hiddenTitle: true,
      visible: false,
    });
    webview.once("tauri://created", function () {
      console.log("webview window created");
      webview.hide();
    });
    webview.once("tauri://error", function (e) {
      console.log("webview window error", e);
    });
  }
}
