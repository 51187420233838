import clsx from "clsx";
import React, { PropsWithChildren } from "react";

export const SpeedDialButton: React.FC<PropsWithChildren & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  ...props
}) => {
  const disabled = props.disabled;

  return (
    <button
      type="button"
      data-dial-toggle="speed-dial-menu-default"
      aria-controls="speed-dial-menu-default"
      aria-expanded="false"
      className={clsx(
        "flex h-14 w-14 items-center justify-center rounded-full text-white transition-colors duration-500 hover:bg-blue-800 focus:outline-none focus:ring-4",
        {
          "bg-blue-700 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800":
            !disabled,
          "bg-gray-200 dark:bg-gray-400": disabled,
        },
      )}
      {...props}
    >
      {children}
    </button>
  );
};

const SpeedDial: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div data-dial-init className="group fixed bottom-6 end-6">
      {children}
    </div>
  );
};

export { SpeedDial };
