import { isNil, keys, mapKeys } from "@mixitone/util";
import Debug from "debug";
import { SubscriptionManager, SubscriptionSource } from "./SubscriptionManager";
import { DatabaseAdapter } from "./adapters/DatabaseAdapter";
import { Collection, CollectionDescriptor } from "./collection";
import { AnyModelConfig, ModelCollections, ModelFields, ModelFieldsWithId, ModelReferences } from "./config";
import { NotFoundError } from "./errors";
import { Filter, matches } from "./filter";
import { GetModelConfig, Model, ModelConstructor } from "./model";
import { ModelRegistry, WaitOptions } from "./modelRegistry";
import { ReferenceDescriptor, References } from "./reference";
import requestQueue from "./requestQueue";

const debug = Debug("oom:query");

interface Order<F extends AnyModelConfig> {
  column: string & keyof ModelFields<F>;
  direction: "asc" | "desc";
}

export type BinaryOperator = "eq" | "gt" | "gte" | "lt" | "lte" | "neq";
export type ArrayOperator = "in";
export type Operator = BinaryOperator | ArrayOperator;

interface BinaryOperatorValue<T> {
  op: BinaryOperator;
  value: T;
}
interface ArrayOperatorValue<T> {
  op: ArrayOperator;
  value: T[];
}
export type QueryFilterValue<T> = BinaryOperatorValue<T> | ArrayOperatorValue<T>;

export type QueryFilter<F extends AnyModelConfig> = {
  [P in keyof ModelFields<F> & string]?: QueryFilterValue<ModelFields<F>[P]>;
};

type FilterValue<T> = T extends Date ? T | "today" : T;
type FilterValueFor<C extends AnyModelConfig, K extends keyof ModelFieldsWithId<C> & string> = FilterValue<
  ModelFields<C>[K]
>;

function queryFilterToSimpleFilter<F extends AnyModelConfig>(filter: QueryFilter<F>): Filter {
  return keys(filter).reduce((acc, k) => {
    const value = filter[k];
    if (!value) return acc;

    return { ...acc, [k]: value.value };
  }, {});
}

interface SubscriptionCallbackInsert<M extends Model<any>> {
  type: "INSERT";
  model: M;
  source: SubscriptionSource;
}
interface SubscriptionCallbackUpdate<M extends Model<any>> {
  type: "UPDATE";
  model: M;
  source: SubscriptionSource;
}
interface SubscriptionCallbackDelete<M extends Model<any>> {
  type: "DELETE";
  model: M;
  source: SubscriptionSource;
}
export type SubscriptionCallbackPayload<M extends Model<any>> =
  | SubscriptionCallbackInsert<M>
  | SubscriptionCallbackUpdate<M>
  | SubscriptionCallbackDelete<M>;

export type SubscriptionCallback<M extends Model<any>> = (payload: SubscriptionCallbackPayload<M>) => void;

interface FindOptions {
  noCache?: boolean;
}

interface BaseQueryOptions {
  adapter: DatabaseAdapter;
}

interface QueryOptions<C extends AnyModelConfig> {
  filter?: QueryFilter<C>;
  order?: Order<C>;
  limit?: number;
  preloadCounts?: string[];
  preloadCollections?: Array<keyof ModelCollections<C>>;
  preloadReferences?: Array<keyof ModelReferences<C> & string>;
  from?: string;
  selection?: string[];
  joins?: Query<any, any>[];
  includeDeleted?: boolean;
  adapter: DatabaseAdapter;
}

export interface SelectableQuery {
  get selection(): string[];
}

export interface WhereableQuery<C extends AnyModelConfig> {
  get filter(): QueryFilter<C>;

  eq<K extends keyof ModelFieldsWithId<C> & string>(
    field: K,
    value: undefined | null | (K extends "id" ? string : ModelFields<C>[K]),
  ): WhereableQuery<C>;
  neq<K extends keyof ModelFieldsWithId<C> & string>(
    field: K,
    value: undefined | null | (K extends "id" ? string : ModelFields<C>[K]),
  ): WhereableQuery<C>;
  gt<K extends keyof ModelFieldsWithId<C> & string>(field: K, value: FilterValueFor<C, K>): WhereableQuery<C>;
  gte<K extends keyof ModelFieldsWithId<C> & string>(
    field: K,
    value: FilterValueFor<C, K>,
  ): WhereableQuery<C>;
  lt<K extends keyof ModelFieldsWithId<C> & string>(field: K, value: FilterValueFor<C, K>): WhereableQuery<C>;
  lte<K extends keyof ModelFieldsWithId<C> & string>(
    field: K,
    value: FilterValueFor<C, K>,
  ): WhereableQuery<C>;
  in<K extends (keyof ModelFields<C> & string) | "id">(
    field: K,
    value: Array<K extends "id" ? string : ModelFields<C>[K]>,
  ): WhereableQuery<C>;
}

export class BaseQuery<M extends Model<any>, C extends GetModelConfig<M>> {
  config: C;

  constructor(
    public modelClass: ModelConstructor<M>,
    public options: BaseQueryOptions,
  ) {
    this.config = modelClass.config;
  }

  get adapter() {
    return this.options.adapter;
  }

  get tableName() {
    return this.modelClass.tableName;
  }
}

export class Query<M extends Model<any>, C extends GetModelConfig<M>>
  extends BaseQuery<M, C>
  implements SelectableQuery, WhereableQuery<C>
{
  constructor(
    modelClass: ModelConstructor<M>,
    public options: QueryOptions<C>,
  ) {
    super(modelClass, options);

    if (isNil(this.options.selection)) {
      this.options.selection = keys(this.config.fields) as string[];
    }
  }

  get filter(): QueryFilter<C> {
    return this.options.filter || {};
  }

  get order() {
    return this.options.order;
  }

  get preloadCollections() {
    return this.options.preloadCollections;
  }

  get preloadReferences() {
    return this.options.preloadReferences;
  }

  get hasPreloads() {
    return this.preloadCollections || this.preloadReferences || this.options.preloadCounts;
  }

  get tableName() {
    return this.options.from || super.tableName;
  }

  get selection() {
    const selection = this.options.selection || [];
    selection.push("id");
    return selection;
  }

  get joinQueries() {
    return this.options.joins || [];
  }

  get joinsFilter() {
    return this.joinQueries.reduce((acc, join) => {
      const joinFilter = mapKeys(join.filter, (k) => `${join.tableName}.${k}`);
      return { ...acc, ...joinFilter };
    }, {});
  }

  /**
   * Returns true if the given model would match this query
   */
  matches(model: M) {
    if (model.rawAttributes["is_deleted"] === true) return false;
    return matches(queryFilterToSimpleFilter(this.filter), model);
  }

  static inFlightRequests = new Map<string, WeakRef<Promise<any>>>();

  /**
   * Execute the query filtering to a single record by id
   *
   * @param id
   * @param options
   */
  async find(id: string, options: FindOptions = {}): Promise<M> {
    const requestKey = ModelRegistry.instance.keyFor(this.tableName, id);
    let model;

    if (!options.noCache) {
      const inFlight = Query.inFlightRequests.get(requestKey);
      if (inFlight) {
        const promise = inFlight.deref();
        if (promise) await promise;
      }

      model = ModelRegistry.instance.get(this.modelClass, id);
      if (model) await this.loadPreloads([model]);
    }

    if (!model) {
      const request = async () => {
        const response = await requestQueue.add(() => this.adapter.execute(this.eq("id", id).limit(1)));
        if (!response) throw new NotFoundError();
        if (response.error || !response.data || response.data.length < 1)
          throw new NotFoundError(response.error?.message || "Not found");
        const model = new this.modelClass(response.data[0] as any);
        await this.loadPreloads([model]);
        return model;
      };

      const promise = request();
      Query.inFlightRequests.set(requestKey, new WeakRef(promise));
      model = await promise.finally(() => Query.inFlightRequests.delete(requestKey));
    }

    return model;
  }

  /**
   * Find a single record with the given criteria. This is the same as running `.eq(field, value).first()`
   *
   * @param field
   * @param value
   * @returns
   */
  async findBy<K extends keyof ModelFields<C> | "id">(
    field: K,
    value: ModelFields<C>[K],
  ): Promise<undefined | M> {
    if (typeof field !== "string") throw new Error("not a string");

    return this.eq(field, value).first();
  }

  /**
   * Execute the query with a limit of 1 and return the first record
   */
  async first(): Promise<undefined | M> {
    const limitQuery = this.limit(1);
    const models = await limitQuery.all();

    if (models.length === 0) return;
    return models[0];
  }
  /**
   * If a model is going to be loaded by another part of the application then
   * this method can be used to wait for it to be loaded. If it never gets
   * loaded then this will timeout
   */
  async waitFirst(options?: WaitOptions): Promise<M> {
    return ModelRegistry.instance.wait<M>(this, options);
  }
  /**
   * Perform a count operation
   */
  async count(): Promise<number> {
    const response = await this.adapter.count(this);

    if (response.error) throw new Error(response.error.message);
    if (!response.count) return 0;

    return response.count;
  }
  /**
   * Return all records matching the query
   */
  async all(): Promise<M[]> {
    const response = await requestQueue.add(async () => await this.adapter.execute(this));
    if (!response || response.error) {
      throw new Error(response?.error?.message || "Error executing query");
    }

    const models = response.data ? response.data.map((row: any) => new this.modelClass(row)) : [];

    await this.loadPreloads(models);
    return models;
  }

  /**
   * Return just the specific field data for a query
   */
  async pluck<K extends keyof ModelFieldsWithId<C>>(field: K & string): Promise<ModelFieldsWithId<C>[K][]> {
    const query = this.reselect(field);
    const response = await requestQueue.add(async () => await this.adapter.execute<Record<K, any>>(query));

    if (!response) throw new NotFoundError();
    if (response.error) throw new Error(response.error.message);
    if (!response.data) return [];

    return response.data.map((row: any) => row[field]);
  }

  async loadPreloads(models: M[]): Promise<void> {
    await Promise.all([this.loadReferences(models), this.loadCollections(models), this.loadCounts(models)]);
  }

  async loadReferences<M extends Model<any>>(models: M[]): Promise<void> {
    if (!this.preloadReferences) return;

    for (const reference of this.preloadReferences) {
      const referenceConfig = this.config.references[reference] as ReferenceDescriptor<any>;

      const idMap = models.reduce<Record<string, M[]>>((acc, model) => {
        if (!model.readReference(reference as string)?.loaded) {
          const id = model.readAttribute(referenceConfig.key);
          if (id) {
            acc[id] = acc[id] ? [...acc[id], model] : [model];
          }
        }
        return acc;
      }, {});

      const ids = keys(idMap);
      if (ids.length === 0) continue;

      const referenceModels = await referenceConfig.model().query().in("id", ids).all();

      referenceModels.forEach((refModel) => {
        idMap[refModel.id]?.forEach((model) => model.readReference(reference as string)?.set(refModel));
      });
    }
  }

  /**
   * Load all the given collections on a set of models
   */
  async loadCollections(models: M[]): Promise<void> {
    if (!this.preloadCollections) return;

    const collectionLoads = this.preloadCollections.map((collection) =>
      this.loadCollection(models, collection),
    );
    await Promise.all(collectionLoads);
  }

  async loadCounts(models: M[]): Promise<void> {
    if (!this.options.preloadCounts) return;

    const countLoads = this.options.preloadCounts.map((collection) => this.loadCount(models, collection));
    await Promise.all(countLoads);
  }

  async loadCount<N extends keyof C["collections"]>(models: M[], collectionName: N): Promise<void> {
    const collectionConfig = this.config.collections[collectionName] as CollectionDescriptor<any>;
    if (!collectionConfig) throw new Error("Collection not found");

    const key = collectionConfig.key;
    const query = collectionConfig.model().query();

    for (const model of models) {
      let collectionData = model.data.get(collectionName as string) || [];
      if (isNil(collectionData[0])) {
        const count = await query.eq(key, model.id).count();
        collectionData[0] = { count };
        model.data.set(collectionName as string, collectionData);
      }
    }
  }

  /**
   * Load the given collection on a set of models
   */
  async loadCollection<N extends keyof C["collections"]>(models: M[], collectionName: N): Promise<void> {
    const collectionConfig = this.config.collections[collectionName] as CollectionDescriptor<any>;
    if (!collectionConfig) throw new Error("Collection not found");

    // Filter out models that already have the collection loaded
    const modelsNeedingLoading = models.filter(
      (model) => !model.readCollection(collectionName as string)?.loaded,
    );
    // Nothing needs loading
    if (modelsNeedingLoading.length === 0) return;

    const modelIds = modelsNeedingLoading.map((model) => model.id!);
    const key = collectionConfig.key;
    const collectionModel = collectionConfig.model();
    const query = collectionModel
      .query()
      .orderBy(collectionConfig.order || "id")
      .in(key, modelIds);

    const collectionModels = await query.all();

    debug("collection load", collectionModels.length, "models for", collectionName);

    const collectionReverseReference = Object.entries(collectionModel.config.references as References).find(
      ([_, ref]) => ref.model() === this.modelClass && ref.key === collectionConfig.key,
    );

    for (const model of modelsNeedingLoading) {
      const collectionDataForModel = collectionModels.filter((m) => m[key] === model.id);
      const collection = model.readCollection(collectionName as string) as Collection<any>;
      collection.set(collectionDataForModel);

      if (collectionReverseReference) {
        collectionDataForModel.forEach((m) => {
          m.readReference(collectionReverseReference[0])?.set(model);
        });
      }
    }
  }

  /**
   * Create a subscription for changes for records that match this query
   */
  async subscribe(callback: SubscriptionCallback<M>, filter?: Partial<ModelFields<C>>): Promise<() => void> {
    return await SubscriptionManager.instance.add(
      this.modelClass.tableName,
      (filter || {}) as any,
      (data, source) => {
        const model = new this.modelClass(data.eventType === "DELETE" ? data.old : (data.new as any));
        const type = data.eventType as "INSERT" | "UPDATE" | "DELETE";

        // when the type is update or insert and the new row only has the id
        // then reload the model using the query
        if ((type === "UPDATE" || type === "INSERT") && Object.keys(data.new).length === 1) {
          this.find(model.id!, { noCache: true })
            .then((m) => {
              callback({ type, model: m, source });
            })
            .catch((e) => {
              console.error("Error reloading model", e);
            });
          return;
        }

        return callback({ type, model, source });
      },
    );
  }

  /**
   * When this query executes it will preload the given collections
   */
  preload<K extends keyof (ModelCollections<C> & ModelReferences<C>)>(...preloads: K[]): Query<M, C> {
    const collections = preloads.filter((p) => p in this.modelClass.config.collections);
    const references = preloads.filter((p) => p in this.modelClass.config.references);

    return new Query<M, C>(this.modelClass, {
      ...this.options,
      preloadCollections: collections as any,
      preloadReferences: references as any,
    });
  }

  /**
   * When this query executes it will preload the given collection counts
   */
  preloadCounts<K extends keyof ModelCollections<C>>(...collections: K[]): Query<M, C> {
    return new Query<M, C>(this.modelClass, {
      ...this.options,
      preloadCounts: collections as any,
    });
  }

  /**
   * Apply an order to the query
   */
  orderBy<K extends keyof ModelFields<C> & string>(field: K, direction: "asc" | "desc" = "asc"): Query<M, C> {
    if (typeof field !== "string") throw new Error("not a string");

    return new Query(this.modelClass, {
      ...this.options,
      order: { column: field, direction },
    });
  }

  // Generic method to handle setting up filter criteria
  private addFilter<K extends keyof ModelFieldsWithId<C> & string>(
    field: K,
    value:
      | undefined
      | null
      | (K extends "id" ? string : ModelFields<C>[K])
      | (K extends "id" ? string : ModelFields<C>[K])[],
    op: BinaryOperator | "in",
  ): Query<M, C> {
    if (typeof field !== "string") throw new Error("not a string");

    return new Query(this.modelClass, {
      ...this.options,
      filter: {
        ...this.filter,
        [field]: { op, value },
      },
    });
  }

  // Refactored methods using the generic addFilter method
  eq<K extends keyof ModelFieldsWithId<C> & string>(
    field: K,
    value: undefined | null | (K extends "id" ? string : FilterValueFor<C, K>),
  ): Query<M, C> {
    return this.addFilter(field, value, "eq");
  }
  neq<K extends keyof ModelFieldsWithId<C> & string>(
    field: K,
    value: undefined | null | (K extends "id" ? string : FilterValueFor<C, K>),
  ): Query<M, C> {
    return this.addFilter(field, value, "neq");
  }
  gt<K extends keyof ModelFieldsWithId<C> & string>(field: K, value: FilterValueFor<C, K>): Query<M, C> {
    return this.addFilter(field, value, "gt");
  }
  gte<K extends keyof ModelFieldsWithId<C> & string>(field: K, value: FilterValueFor<C, K>): Query<M, C> {
    return this.addFilter(field, value, "gte");
  }
  lt<K extends keyof ModelFieldsWithId<C> & string>(field: K, value: FilterValueFor<C, K>): Query<M, C> {
    return this.addFilter(field, value, "lt");
  }
  lte<K extends keyof ModelFieldsWithId<C> & string>(field: K, value: FilterValueFor<C, K>): Query<M, C> {
    return this.addFilter(field, value, "lte");
  }
  in<K extends (keyof ModelFields<C> & string) | "id">(
    field: K,
    value: Array<K extends "id" ? string : FilterValueFor<C, K>>,
  ) {
    if (!Array.isArray(value)) throw new Error("value must be an array");
    return this.addFilter(field, value, "in");
  }

  /**
   * Limit the number of records returned by the query
   */
  limit(limit: number | undefined) {
    return new Query(this.modelClass, {
      ...this.options,
      limit,
    });
  }

  /**
   * Change the table or view that the query is running against
   */
  from(tableOrViewName: string) {
    return new Query(this.modelClass, {
      ...this.options,
      from: tableOrViewName,
    });
  }

  /**
   * Add additional fields to the selection
   */
  select(...selection: string[]) {
    return this.reselect(...(this.options.selection || []), ...selection);
  }

  /**
   * Change the selection to the given fields
   */
  reselect(...selection: string[]) {
    return new Query(this.modelClass, {
      ...this.options,
      selection,
    });
  }

  joins(query: Query<any, any>) {
    return new Query(this.modelClass, {
      ...this.options,
      joins: [...(this.options.joins || []), query],
    });
  }

  includeDeleted() {
    return new Query(this.modelClass, {
      ...this.options,
      includeDeleted: true,
    });
  }

  async updateAll(attributes: Partial<ModelFields<C>>): Promise<void> {
    const updateQuery = new UpdateQuery(this.modelClass, {
      adapter: this.adapter,
      filter: this.filter,
      selection: ["*"],
    });
    await updateQuery.set(attributes);
  }
}

interface InsertQueryOptions {
  adapter: DatabaseAdapter;
  selection?: string[];
}

export class InsertQuery<M extends Model<any>, C extends GetModelConfig<M>>
  extends BaseQuery<M, C>
  implements SelectableQuery
{
  constructor(
    modelClass: ModelConstructor<M>,
    public options: InsertQueryOptions,
  ) {
    super(modelClass, options);

    if (isNil(this.options.selection)) {
      this.options.selection = keys(this.config.fields) as string[];
    }
  }

  get selection() {
    return this.options.selection || [];
  }

  async insert(attributes: Partial<ModelFields<C>>): Promise<M> {
    const response = await this.adapter.insert<any>(this, attributes);
    if (response.error) {
      throw new Error(response.error.message);
    }
    const model = new this.modelClass(response.data as any);
    return model;
  }

  /**
   * Change the selection to the given fields
   */
  select(...selection: string[]) {
    return new InsertQuery(this.modelClass, {
      ...this.options,
      selection,
    });
  }
}
interface UpdateQueryOptions<C extends AnyModelConfig> {
  filter?: QueryFilter<C>;
  adapter: DatabaseAdapter;
  selection?: string[];
  single?: boolean;
}

export class UpdateQuery<M extends Model<any>, C extends GetModelConfig<M>>
  extends BaseQuery<M, C>
  implements SelectableQuery, WhereableQuery<C>
{
  constructor(
    modelClass: ModelConstructor<M>,
    public options: UpdateQueryOptions<C>,
  ) {
    super(modelClass, options);
  }

  get filter(): QueryFilter<C> {
    return this.options.filter || {};
  }

  get selection() {
    return this.options.selection || [];
  }

  async set(attributes: Partial<ModelFields<C>>) {
    const response = await this.adapter.update(this, attributes);
    if (response.error) {
      throw new Error(response.error.message);
    }
  }

  async delete() {
    const response = await this.adapter.delete(this);
    if (response.error) {
      throw new Error(response.error.message);
    }
  }

  /**
   * Change the selection to the given fields
   */
  select(...selection: string[]) {
    return new UpdateQuery(this.modelClass, {
      ...this.options,
      selection,
    });
  }

  // Generic method to handle setting up filter criteria
  private addFilter<K extends keyof ModelFieldsWithId<C> & string>(
    field: K,
    value:
      | undefined
      | null
      | (K extends "id" ? string : ModelFields<C>[K])
      | (K extends "id" ? string : ModelFields<C>[K])[],
    op: BinaryOperator | "in",
  ): UpdateQuery<M, C> {
    if (typeof field !== "string") throw new Error("not a string");

    return new UpdateQuery(this.modelClass, {
      ...this.options,
      filter: {
        ...this.filter,
        [field]: { op, value },
      },
    });
  }

  // Refactored methods using the generic addFilter method
  eq<K extends keyof ModelFieldsWithId<C> & string>(
    field: K,
    value: undefined | null | (K extends "id" ? string : FilterValueFor<C, K>),
  ) {
    return this.addFilter(field, value, "eq");
  }
  neq<K extends keyof ModelFieldsWithId<C> & string>(
    field: K,
    value: undefined | null | (K extends "id" ? string : FilterValueFor<C, K>),
  ) {
    return this.addFilter(field, value, "neq");
  }
  gt<K extends keyof ModelFieldsWithId<C> & string>(field: K, value: FilterValueFor<C, K>) {
    return this.addFilter(field, value, "gt");
  }
  gte<K extends keyof ModelFieldsWithId<C> & string>(field: K, value: FilterValueFor<C, K>) {
    return this.addFilter(field, value, "gte");
  }
  lt<K extends keyof ModelFieldsWithId<C> & string>(field: K, value: FilterValueFor<C, K>) {
    return this.addFilter(field, value, "lt");
  }
  lte<K extends keyof ModelFieldsWithId<C> & string>(field: K, value: FilterValueFor<C, K>) {
    return this.addFilter(field, value, "lte");
  }
  in<K extends (keyof ModelFields<C> & string) | "id">(
    field: K,
    value: Array<K extends "id" ? string : FilterValueFor<C, K>>,
  ) {
    if (!Array.isArray(value)) throw new Error("value must be an array");
    return this.addFilter(field, value, "in");
  }
}
