import { Model, ModelAttributes, model, modelConfig } from "@mixitone/oom";

const config = modelConfig(
  {
    created_at: {
      type: "datetime",
    },
    feedback_message_id: {
      type: "string",
    },
    is_deleted: {
      type: "boolean",
    },
    updated_at: {
      type: "datetime",
    },
    user_id: {
      type: "string",
    },
    viewed_at: {
      type: "datetime",
    },
  },
  {
    // Add collections here if needed
  },
  {
    // Add references here if needed
  },
);

interface UserFeedbackMessagesState extends ModelAttributes<typeof config> {}

@model("user_feedback_messages_states", config)
class UserFeedbackMessagesState extends Model<typeof config> {}

export default UserFeedbackMessagesState;
