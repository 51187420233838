import { isNil, isObjectLike } from "@mixitone/util";
import { Model, ModelConstructor } from "./model";
import { Query } from "./query";

type Key<T extends string = string, I extends string = string> = `${T}-${I}`;
type UnknownModel = Model<any>;
export interface WaitOptions {
  timeout?: number;
}

/**
 * A key value store of all models that have been instantiated. This is used to
 * lookup models by their primary key. The values are weak references.
 */
class ModelRegistry {
  static instance = new ModelRegistry();

  models = new Map<Key, WeakRef<UnknownModel>>();
  finders = new Map<string, (id: string) => Promise<UnknownModel>>();

  constructor() {}

  register(model: UnknownModel) {
    if (!model.persisted) return;

    const key = this.keyFor(model);

    if (this.models.has(key)) {
      // Copy over any collections or references that have been loaded
      const existing = this.models.get(key)?.deref();
      if (existing && existing !== model) {
        Object.keys(model.collections).forEach((name) => {
          if (model.readCollection(name).loaded && !existing.readCollection(name).loaded) {
            existing.replaceCollection(name, model.readCollection(name));
          } else if (!model.readCollection(name).loaded && existing.readCollection(name).loaded) {
            model.replaceCollection(name, existing.readCollection(name));
          }
        });
        Object.keys(model.references).forEach((name) => {
          if (model.readReference(name).loaded && !existing.readReference(name).loaded) {
            existing.replaceReference(name, model.readReference(name));
          } else if (!model.readReference(name).loaded && existing.readReference(name).loaded) {
            model.replaceReference(name, existing.readReference(name));
          }
        });
      }
    }

    this.models.set(key, new WeakRef(model));

    this.resolveWaits();
  }

  unregister(model: UnknownModel) {
    if (isNil(model.id)) return;

    this.models.delete(this.keyFor(model));
  }

  has(model: UnknownModel): boolean;
  has(key: Key): boolean;
  has<M extends Model<any>>(model: ModelConstructor<M>, id: string): boolean;
  has(...args: any[]) {
    if (args.length === 1) {
      const key = args[0] instanceof Model ? this.keyFor(args[0]) : args[0];
      return isObjectLike(this.models.get(key)?.deref());
    } else {
      const [model, id] = args;
      const key = this.keyFor(model.tableName, id);
      return this.has(key);
    }
  }

  get<M extends Model<any>>(key: Key<M["tableName"]>): M | undefined;
  get<M extends Model<any>>(model: ModelConstructor<M>, id: string): M | undefined;
  get<M extends Model<any>>(...args: any[]): UnknownModel | undefined {
    if (args.length === 1) {
      const key = args[0] as Key;
      const model = this.models.get(key)?.deref();
      const expectedTable = key.split("-")[0];
      if (model?.tableName !== expectedTable) return undefined;
      return model as unknown as M;
    } else {
      const [model, id] = args;
      const key = this.keyFor(model.tableName, id);
      return this.get(key);
    }
  }

  keyFor(tableName: string, id: string): Key;
  keyFor(model: UnknownModel): Key;
  keyFor(...args: any[]): Key {
    if (args.length === 1) {
      const [model] = args as [UnknownModel];
      return `${model.tableName}-${model.id}`;
    } else {
      const [tableName, id] = args as [string, string];
      return `${tableName}-${id}`;
    }
  }

  _waits: Set<{
    query: Query<any, any>;
    resolve: (model: Model<any>) => void;
  }> = new Set();

  /**
   * Wait for a model to be registered that matches the given query. This is
   * useful for when you're doing something like displaying a modal over another
   * page and you know that page will be loading the model. By default this
   * times out after 10 seconds.
   */
  wait<M extends Model<any>>(query: Query<M, any>, options?: WaitOptions): Promise<M> {
    const promise = new Promise<M>((resolve: (model: M) => void, reject) => {
      const wait = { query, resolve: resolve as any };
      this._waits.add(wait);
      this.resolveWaits();

      const timeout = options?.timeout ?? 10000;
      setTimeout(() => {
        if (this._waits.has(wait)) {
          this._waits.delete(wait);
          reject(new Error(`Wait for model timed out after ${timeout}ms`));
        }
      }, timeout);
    });
    return promise;
  }

  resolveWaits() {
    this._waits.forEach((wait) => {
      const { query, resolve } = wait;

      for (let [key, ref] of this.models) {
        const model = ref.deref();
        if (!model) {
          this.models.delete(key);
          continue;
        }

        if (query.modelClass.tableName !== model.tableName) continue;

        if (query.matches(model)) {
          resolve(model);
          this._waits.delete(wait);
          return;
        }
      }
    });
  }
}

export { ModelRegistry };
const instance = ModelRegistry.instance;
export default instance;
