import ApplicationController, { ApplicationView } from "@mixitone/mvc";
import dayjs from "dayjs";

interface Props {
  date: string | Date;
  className?: string;
}

interface State {
  date: string | Date;
  relativeTime: string;
}

class FromNowController extends ApplicationController<State, Props> {
  timer: ReturnType<typeof setInterval> | null = null;

  async changeProps(newProps: Props) {
    this.state.date = newProps.date;
    this.updateRelativeTime();
    this.timer = setInterval(() => this.updateRelativeTime(), 60000);
  }

  updateRelativeTime() {
    this.state.relativeTime = dayjs(this.state.date).fromNow();
  }

  destroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}

const FromNow: React.FC<Props> = ({ className }) => {
  const controller = FromNowController.use();
  const { relativeTime } = controller.state;

  return <span className={className}>{relativeTime}</span>;
};

/**
 * Show a relative time from now that updates every minute
 *
 * @usage
 * ```tsx
 * <FromNow date={user.signedUpAt} />
 * ```
 */
export default FromNowController.scope(ApplicationView(FromNow));
