import { Model, ModelAttributes, collection, model, modelConfig } from "@mixitone/oom";
import UserFeedbackMessagesState from "./UserFeedbackMessagesState";

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    admin_id: {
      type: "string",
    },
    admin_name: {
      type: "string",
    },
    created_at: {
      type: "datetime",
    },
    email: {
      type: "string",
    },
    from_user: {
      type: "boolean",
    },
    is_deleted: {
      type: "boolean",
    },
    message: {
      type: "string",
    },
    reply_to_id: {
      type: "string",
    },
    thread_id: {
      type: "string",
    },
    updated_at: {
      type: "datetime",
    },
    user_id: {
      type: "string",
    },
  },
  {
    // Add collections here if needed
    states: collection(() => UserFeedbackMessagesState, "feedback_message_id"),
  },
  {
    // Add references here if needed
  },
);

interface FeedbackMessage extends ModelAttributes<typeof config> {}

@model("feedback_messages", config)
class FeedbackMessage extends Model<typeof config> {
  get sending() {
    return this.id === "temp";
  }
}

export default FeedbackMessage;
