import { useEffect, useState } from "react";

type MouseEventKeyMap<T> = {
  [P in keyof T as T[P] extends MouseEvent ? P : never]: T[P];
};
type MouseEventKey = keyof MouseEventKeyMap<DocumentEventMap>;

interface Options {
  /** The mouse event to listen for. Defaults to "mousedown" so clicking triggers
   * the alert. But a tooltip popup might prefer "mouseover" */
  event?: MouseEventKey;
  skipElements?: string[];
  active?: boolean;
  delay?: number;
}

/**
 * Hook that alerts clicks outside of the passed ref element
 *
 * source: https://stackoverflow.com/a/42234988/810
 */
export default function useOutsideAlerter(
  ref: React.RefObject<HTMLElement | null>,
  callback: (event: MouseEvent) => void,
  options: Options = {},
  deps?: any[],
) {
  const { active = true } = options;
  const [root, setRoot] = useState<ShadowRoot | null>(null);
  const event: MouseEventKey = options.event || "mousedown";
  const element = ref.current;

  useEffect(() => {
    if (!element) return;

    let parent: Node | null = element;
    while (parent) {
      if (parent instanceof ShadowRoot) {
        setRoot(parent);
        break;
      }
      parent = parent.parentNode;
    }
  }, [element]);

  useEffect(() => {
    if (!active) return;

    function handleClickOutside(event: MouseEvent) {
      if (options.skipElements) {
        for (const skip of options.skipElements) {
          if ((event.target as Element).closest(skip)) return;
        }
      }

      if (event.target && ref.current && !ref.current.contains(event.target as Element)) {
        if (root && (event.target as Element).shadowRoot === root) return;
        callback(event);
      }
    }

    const addEvents = () => {
      document.addEventListener(event, handleClickOutside);
      if (root) {
        root.addEventListener(event, handleClickOutside as any);
      }
    };

    if (options.delay) {
      setTimeout(addEvents, options.delay);
    } else {
      addEvents();
    }

    return () => {
      document.removeEventListener(event, handleClickOutside);
      if (root) {
        root.removeEventListener(event, handleClickOutside as any);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, callback, root, event, ...(deps || [])]);
}
