import { Modal } from "./Modal";
import { Spinner } from "./Spinner";

interface Props {
  title: string;
  className?: string;
}

export const LoadingModal: React.FC<Props> = ({ title, className }) => {
  return (
    <Modal title={title} className={className} onClose={() => {}}>
      <div className="flex items-center justify-center">
        <Spinner size={64} />
      </div>
    </Modal>
  );
};
