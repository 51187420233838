import { Session, SupabaseClient } from "@supabase/supabase-js";
import { SessionAdapter, isAuthEvent } from "./SessionAdapter";

function adaptedSession(session: Session) {
  return { ...session, adapter: "supabase" };
}

export class SupabaseSessionAdapter extends SessionAdapter {
  constructor(private supabase: SupabaseClient) {
    super();

    // I needed to add this after updating supbase gotrue-js to 2.46.1
    // due to https://github.com/supabase/gotrue-js/pull/747
    this.supabase.auth.initialize();

    this.supabase.auth.onAuthStateChange(async (event, supabaseSession) => {
      if (isAuthEvent(event)) {
        const session = supabaseSession ? adaptedSession(supabaseSession) : null;
        this.emit(event, session);
      }
    });
  }

  get canSignUp() {
    return true;
  }

  get canSignIn() {
    return true;
  }

  get canSignOut() {
    return true;
  }

  get canResetPassword() {
    return true;
  }

  async signOut() {
    const { error } = await this.supabase.auth.signOut();
    if (error) return { error };
    return { data: undefined };
  }

  async signUp(email: string, password: string, options?: { data: { name: string; inviteToken?: string } }) {
    return await this.supabase.auth.signUp({ email, password, options });
  }

  async signInWithPassword(email: string, password: string) {
    const { error, data } = await this.supabase.auth.signInWithPassword({ email, password });
    if (!data) {
      return { error: new Error("No session returned") };
    }
    const { user, session } = data;
    if (!(session && user)) {
      return { error: error || new Error("No session or user returned") };
    }

    return {
      data: {
        session: adaptedSession(session),
        user,
      },
    };
  }

  async resetPasswordForEmail(email: string, redirectTo: string) {
    const { error } = await this.supabase.auth.resetPasswordForEmail(email, { redirectTo });
    if (error) return { error };
    return { data: {} };
  }

  async updatePassword(password: string) {
    const { error } = await this.supabase.auth.updateUser({ password });
    if (error) return { error };
    return { data: {} };
  }

  async updateName(name: string) {
    const { error } = await this.supabase.auth.updateUser({ data: { name } });
    if (error) return { error };
    return { data: {} };
  }

  async getUser() {
    const { error, data } = await this.supabase.auth.getUser();
    if (!data) return { error: error || new Error("No user returned") };
    if (!data.user) return { error: error || new Error("No user returned") };
    return { data };
  }

  async getSession() {
    const { data } = await this.supabase.auth.getSession();
    if (!data?.session) return null;
    return adaptedSession(data.session);
  }

  rpc<T>(
    functionName: string,
    args: any,
  ): Promise<
    | {
        error: undefined;
        data: T;
      }
    | { error: Error; data: undefined }
  > {
    return new Promise((resolve, reject) => {
      this.supabase.rpc(functionName, args).then(
        (response) => {
          resolve(response as any);
        },
        (reason) => reject(reason),
      );
    });
  }

  async edgeFunction(functionName: string, args: any) {
    const response = await this.supabase.functions.invoke(functionName, {
      body: JSON.stringify(args),
    });
    if (response.error) {
      throw new Error(response.error.message);
    }
    return response.data;
  }
}
